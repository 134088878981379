import * as React from 'react';
// import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: '"SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace',
      textTransform: 'none',
      fontSize: '14px',
    },
  },
});

const drawerWidth = 240;
// const navItems = ['About', 'Experience', 'Work', 'Contact'];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);



  //Scroll ID
  // const handleClickScroll = () => {
  //   const element = document.getElementById("projects" );

  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: 'smooth' });
  //     console.log(element)

  //   }

  // }
 


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>
      <Typography variant="h6" sx={{ my: 2, textAlign: 'center' }}>
        <img src="../logo-02.svg" alt="tinmyowin" height="50" width="50" />
      </Typography>
      <Divider sx={{ borderColor: '#fff' }} />

      <Box className='resumeitem' sx={{ display: { xs: 'block', sm: 'none' } }}>
        <nav>
          <ul>
            <li><a href="#aboutme" className='menu'>About</a></li>
            <li><a href="#experience" className='menu'>Experience</a></li>
            <li><a href="#projects" className='menu'>Projects</a></li>
            <li><a href="#getintouch" className='menu'>Contact</a></li>
          </ul>
        </nav>
        <Box className='resumeDownload mobileResumedDownload'>
          <Button sx={{ textTransform: 'inherit' }} variant="outlined"> <a href='../resume.pdf' target='{_blank}' rel="noreferrer" className='saybutton'>Resume</a></Button>
        </Box>
      </Box>

      {/* {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))} */}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" className="headerNav" >
        <Toolbar sx={{ padding: '0px 25px !important' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography className='animate fadeInDown two'
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, paddingTop: '10px' }}
          >

            <img src="../logo-02.svg" alt="tinmyowin" height="50" width="50" />
          </Typography>
          <Box className='countItem animate fadeInDown two' sx={{ display: { xs: 'none', sm: 'block' } }} >
            <Box className='itemMenu'>
              <ThemeProvider theme={theme}>
                <ul>
                  <li><a href="#aboutme" className='menu'>About</a></li>
                  <li><a href="#experience" className='menu'>Experience</a></li>
                  <li><a href="#projects" className='menu'>Projects</a></li>
                  <li><a href="#getintouch" className='menu'>Contact</a></li>
                </ul>
                {/* {navItems.map((item) => (
                <Button onClick={handleClickScroll} key={item.id} sx={{ color: '#fff', textTransform: 'inherit', fontSize: '13px' }}>
                  {item}
                </Button>
              ))} */}
              </ThemeProvider>

              <Box className='resumeDownload'>
                <Button sx={{ textTransform: 'inherit' }} variant="outlined"> <a href='../resume.pdf' target='{_blank}' rel="noreferrer" className='saybutton'>Resume</a></Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'rgba(10, 25, 47, 0.85)', color: '#fff' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

// DrawerAppBar.propTypes = {
//   window: PropTypes.func,
// };

export default DrawerAppBar;