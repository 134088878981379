import * as React from 'react';
import Box from '@mui/material/Box';

const EmailSent = () => {
  return (
    <>
      <Box className='emailsant' sx={{ display: {xs: 'none' , md: 'block'} }}>
 
          <a href='mailto:brittany.chiang@gmail.com'>tinmyowintmw6@gmail.com</a>

      </Box>
    </>
  );
}

export default EmailSent;